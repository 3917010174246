export interface User {
    id: string;
    name: string;
    email: string;
}

export interface Blog {
    id: string;
    title: string;
    content: string;
    slug: string;
    featured_image: string;
    created_at: string;
    views: number;
    category_name: string;
    tags: string; // Comma-separated tags
    user?: User;
}
// Use environment variable for the base URL
const API_BASE_URL = process.env.REACT_APP_API_URL;

// Function to fetch all blog posts
export const fetchBlogs = async (): Promise<Blog[]> => {
    try {
        const response = await fetch(`${API_BASE_URL}/posts`);
        
        const data = await response.json();
        return data.data; // Assuming the data is inside the "data" field
    } catch (error) {
        console.error('Error fetching blogs:', error);
        throw new Error('Failed to fetch blogs');
    }
};

// Function to fetch a single blog post by ID
export const fetchBlogById = async (id: string): Promise<Blog> => {
    try {
        const response = await fetch(`${API_BASE_URL}/post/${id}`);
        const data = await response.json();
        return data.data; // Assuming the data is inside the "data" field
    } catch (error) {
        console.error('Error fetching blog:', error);
        throw new Error('Failed to fetch blog');
    }
};
