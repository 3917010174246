import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaUser, FaCalendarAlt, FaEye } from 'react-icons/fa'; // Icons for author, date, and views
import '../styles/BlogDetail.css';
import { fetchBlogById, Blog } from '../utils/blogApi'; // Import the API function
import Footer from '../components/Footer'; // Import Footer component

const BlogDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [blog, setBlog] = useState<Blog | null>(null);
    const [error, setError] = useState<string | null>(null); // Handle errors
    const [isLoading, setIsLoading] = useState(true); // Loader state

    useEffect(() => {
        // Check if ID is defined before making the API call
        if (id) {
            const loadBlog = async () => {
                try {
                    const fetchedBlog = await fetchBlogById(id); // Use the API function
                    setBlog(fetchedBlog);
                    setIsLoading(false); // Stop the loader
                } catch (error) {
                    setError('Error fetching blog details');
                    setIsLoading(false); // Stop the loader if there’s an error
                }
            };

            loadBlog(); // Load blog when the component mounts
        } else {
            setError('Invalid blog ID');
            setIsLoading(false);
        }
    }, [id]);

    if (isLoading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (!blog) {
        return <p>Loading blog details...</p>;
    }

    // Parse tags into an array if they are in string format
    const tags = JSON.parse(blog.tags);

    return (
        <div className="blog-detail-container">
            <header className="blog-detail-header">
                <h1>Blog Details</h1>
            </header>

            <div className="blog-content">
                {/* Author, Created Date, and Views */}
                <div className="blog-meta">
                    <span className="meta-item">
                        <FaUser className="meta-icon" /> {blog.user ? blog.user.name : 'Unknown'}
                    </span>
                    <span className="meta-item">
                        <FaCalendarAlt className="meta-icon" /> {new Date(blog.created_at).toLocaleDateString()}
                    </span>
                    <span className="meta-item">
                        <FaEye className="meta-icon" /> {blog.views} views
                    </span>
                </div>
                {/* Full-width image */}
                <img src={blog.featured_image} alt={blog.title} className="blog-detail-image" />

                {/* Title */}
                <h2 className="blog-detail-title">{blog.title}</h2>

                {/* Blog Content */}
                <p className="blog-detail-content">{blog.content}</p>

                {/* Category and Tags in the same line */}
                <div className="category-tags">
                    <span className="blog-category">{blog.category_name}</span>
                    <ul className="blog-tags">
                        {tags.map((tag: string, index: number) => (
                            <li key={index} className="tag-item">#{tag}</li>
                        ))}
                    </ul>
                </div>
                

            </div>

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default BlogDetail;
