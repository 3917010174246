import React from 'react';
import '../styles/Footer.css';

const Footer: React.FC = () => {
    const currentYear = new Date().getFullYear(); // Get the current year

    return (
        <footer className="footer">
            <p>
                Designed by <a href="https://yourwebsite.com" target="_blank" rel="noopener noreferrer">Javed Ali</a> &copy; {currentYear}
            </p>
        </footer>
    );
};

export default Footer;
