import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BlogList from './pages/BlogList';
import BlogDetail from './pages/BlogDetail';

const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                {/* Define the route for the blog list page */}
                <Route path="/" element={<BlogList />} />
                {/* Define the route for the blog detail page */}
                <Route path="/post/:id" element={<BlogDetail />} />
            </Routes>
        </Router>
    );
};

export default App;
