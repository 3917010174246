import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUser, FaCalendarAlt } from 'react-icons/fa'; // Icons for author and date
import '../styles/BlogList.css';
import { fetchBlogs, Blog } from '../utils/blogApi'; // Import the API function
import Footer from '../components/Footer'; // Import the Footer

const BlogList: React.FC = () => {
    const [blogs, setBlogs] = useState<Blog[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true); // Loader state

    useEffect(() => {
        const loadBlogs = async () => {
            try {
                const fetchedBlogs = await fetchBlogs(); // Use the API function
                setBlogs(fetchedBlogs);
                setIsLoading(false); // Data has been fetched, stop loading
            } catch (error) {
                console.error('Error loading blogs:', error);
            }
        };

        loadBlogs(); // Load blogs when the component mounts
    }, []);

    if (isLoading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }

    return (
        <div className="container">
            {/* Blog List Header */}
            <header className="blog-list-header">
                <h1>My Une Blog</h1>
                <p>Stay updated with our latest news, articles, and insights</p>
            </header>

            <div className="row">
                {blogs.length > 0 ? (
                    blogs.map(blog => {
                        // Parse tags into an array if they are in string format
                        const tags = JSON.parse(blog.tags);
                        return (
                            <div className="blog-card" key={blog.id}>
                                <img
                                    src={blog.featured_image}
                                    alt={blog.title}
                                    className="blog-card-image"
                                />
                                <div className="blog-card-body">
                                    {/* Author and Created Date Section */}
                                    <div className="blog-card-meta">
                                        <span className="meta-item">
                                            <FaUser className="meta-icon" /> {blog.user ? blog.user.name : 'Unknown'}
                                        </span>
                                        <span className="meta-item">
                                            <FaCalendarAlt className="meta-icon" /> {new Date(blog.created_at).toLocaleDateString()}
                                        </span>
                                    </div>

                                    {/* Smaller Title */}
                                    <h2 className="blog-card-title">{blog.title}</h2>
                                    <p className="blog-card-text">
                                        {blog.content.substring(0, 100)}...
                                    </p>

                                    {/* Category and Tags in the same line */}
                                    <div className="category-tags">
                                        <ul className="blog-tags">
                                            {tags.map((tag: string, index: number) => (
                                                <li key={index} className="tag-item">#{tag}</li>
                                            ))}
                                        </ul>
                                    </div>

                                </div>
                                {/* Full-width Read More button at the bottom */}
                                <Link to={`/post/${blog.id}`} className="read-more-btn">
                                    Read More
                                </Link>
                            </div>
                        );
                    })
                ) : (
                    <p>No blog posts available.</p>
                )}
            </div>

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default BlogList;
